import * as React from "react";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { MenuItem, variantsLi } from "./MenuItem";
import CourseApplication from "../../forms/CourseApply";

export interface NavLink {
  name: string;
  to: string;
  id: number;
  // trigger: () => void;
  isDropDown?: boolean;
  dropdownItems?: any;
}

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = ({ isOpen, trigger }) => {
  const [applicationModal, setApplictaionModal] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    console.log("secondaryOpen", isOpen);
  }, [isOpen]);

  return (
    <>
      <motion.div>
        <motion.ul
          variants={variants}
          className="navbar-nav"
          initial="closed"
          animate="open"
          exit="closed"
        >
          {navLinks.map((i: NavLink) => (
            <MenuItem
              key={i.id}
              name={i.name}
              to={i.to}
              id={i.id}
              trigger={trigger}
              isDropDown={i.isDropDown ?? false}
              dropdownItems={i.dropdownItems}
            />
          ))}
          <motion.li
            // id={id}
            variants={variantsLi}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="horizontal-navs"
          >
            <a href="https://www.eclub.lk/login?l=lms">LMS</a>
            <a onClick={() => setApplictaionModal(true)}>Apply Now</a>
            <a href="https://www.eclub.lk/login?l=student">Log In</a>
          </motion.li>
        </motion.ul>
      </motion.div>
      {applicationModal && (
        <CourseApplication
          isOpen={applicationModal}
          onClose={() => {
            setApplictaionModal(false);
            trigger();
          }}
        />
      )}
    </>
  );
};

const navLinks = [
  { name: "Home", to: "", id: 5 },
  { name: "About Us", to: "about", id: 6 },
  // { name: "Courses", to: "courses", id: 7 },
  {
    name: "Courses",
    to: "course_details",
    id: 7,
    isDropDown: true,
    dropdownItems: [
      {
        id: 1,
        to: "course_details/ESG",
        courseCategory: "Online Business Education Courses",
        courseId: "ESG",
        courseHeader: "Online Business Education Courses",
        name: "eBay Selling Guide",
      },
      {
        id: 2,
        courseId: "PWS",
        to: "course_details/PWS",

        courseCategory: "Personal Development Workshops",
        courseHeader: "Personal Development Workshops",
        name: "Passionpreneurs’ Workshop (PWS)",
      },
      {
        id: 3,
        to: "course_details/TSG",

        courseId: "TSG",
        courseCategory: "Personal Development Workshops",
        courseHeader: "Personal Development Workshops",
        name: "The Success Guide",
      },
      {
        id: 4,
        to: "course_details/FrMst",

        courseCategory: "Online Business Education Courses",
        courseId: "FrMst",
        courseHeader: "Online Business Education Courses",
        name: "Freelance Masters",
      },
      {
        id: 5,
        to: "course_details/CTG",

        courseCategory: "Online Business Education Courses",
        courseId: "CTG",
        courseHeader: "Online Business Education Courses",
        name: "Crypto Trading Guide",
      },
      {
        id: 6,
        to: "course_details/certificate-course",

        courseCategory: "Education Courses in IT Field",
        courseId: "certificate-course",
        courseHeader: "Education Courses in IT Field",
        name: "Graphic Designing",
      },
      {
        id: 7,
        to: "course_details/ICT-NVQ",

        courseCategory: "Education Courses in IT Field",
        courseId: "ICT-NVQ",
        courseHeader: "Education Courses in IT Field",
        name: "Computer Applications Assistant (NVQ Level 03)",
      },
      {
        id: 10,
        to: "course_details/CIT",

        courseCategory: "Education Courses in IT Field",
        courseId: "CIT",
        courseHeader: "Education Courses in IT Field",
        name: "Certificate in IT",
      },
      {
        id: 8,
        to: "course_details/ACIE",

        courseCategory: "Language Courses",
        courseId: "ACIE",
        courseHeader: "Language Courses",
        name: "Advanced Certificate in English Course (TalkMe English)",
      },
      {
        id: 9,
        to: "course_details/IELTS",

        courseCategory: "Language Courses",
        courseId: "IELTS",
        courseHeader: "Language Courses",
        name: "IELTS",
      },
      {
        id: 11,
        to: "course_details/EPS TOPIK",

        courseCategory: "Language Courses",
        courseId: "EPS TOPIK",
        courseHeader: "Language Courses",
        name: "Korean Language Course - EPS TOPIK",
      },
      {
        id: 12,
        to: "course_details/JLPT N5",

        courseCategory: "Language Courses",
        courseId: "JLPT N5",
        courseHeader: "Language Courses",
        name: "Japanese Language Course – JLPT N5",
      },
      {
        id: 13,
        to: "course_details/JFT",

        courseCategory: "Language Courses",
        courseId: "JFT",
        courseHeader: "Language Courses",
        name: "Japanese Language Course – JFT",
      },
    ],
  },
  // { name: "Free courses", to: "free_courses", id: 8 },
  { name: "Scholarships", to: "scholarships", id: 9 },
  { name: "Life at eClub", to: "life_at_club", id: 10 },
  { name: "eClub youth", to: "eclub_youth", id: 11 },
  { name: "News & Events", to: "news", id: 12 },
  {
    name: "Media",
    to: "media",
    id: 13,
    isDropDown: true,
    dropdownItems: [
      { name: "Gallery", to: "media/gallery", id: 1 },
      { name: "Video", to: "media/video", id: 2 },
    ],
  },
  { name: "Contact Us", to: "contact_us", id: 14 },
];
